// eslint-disable-next-line import/prefer-default-export
export const frostedGlass = {
  background: "rgba( 255, 255, 255, 0.15 )",
  backdropFilter: "blur( 4px )",
  WebkitBackdropFilter: "blur( 4px )",
  borderRadius: "10px",
  border: "1px solid rgba( 255, 255, 255, 0.18 )",
  padding: "1rem",
};

export const backgroundVideo = {
  position: "absolute",
  right: "0",
  bottom: "0",
  minWidth: "100%",
  height: "100vh",
  objectFit: "fill",
};
