// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function ResumeCounters() {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(2018, 8, 20);
  const today = new Date();
  const secondDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto", pt: 4 }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              color="dark"
              count={diffDays}
              title="Days in front off the screen"
              description="Days grinding away building everything from mobile apps, websites to admin dash boards"
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              color="dark"
              count={diffDays - 421}
              suffix="+"
              title="Energy drinks consumed"
              description="Do we really need further elaboration on this?"
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              color="dark"
              count={15}
              suffix="+"
              title="Mobile apps / Websites built"
              description="Number of projects contributed on"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ResumeCounters;
