/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef } from "react";

// typed-js
import * as Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/sunset.gif";
import bgEarth from "assets/images/background/earth.mp4";
import bgColorSplash from "assets/images/background/color-splash1.mp4";
import bgColorSplash2 from "assets/images/background/color-splash2.mp4";
import bgWater from "assets/images/background/water.mp4";

// CSS
import { frostedGlass, backgroundVideo } from "Resume/Styles/Styles";
import { Stack } from "@mui/material";

// get random value from array
const videos = [bgColorSplash, bgColorSplash2, bgEarth, bgWater];
const randVid = videos[Math.floor(Math.random() * videos.length)];

function ResumeHeader() {
  const typedJSRef = useRef(null);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["Web developer", "Mobile developer", "DIY enthusiast", "DJ", "Artist"],
      typeSpeed: 80,
      backSpeed: 80,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <MKBox component="header" position="relative" height="100%">
      <MKBox
        minHeight="100vh"
        width="100%"
        display="flex"
        alignItems="center"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0),
              rgba(gradients.info.state, 0)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <video controls autoPlay loop muted style={backgroundVideo}>
          <source src={randVid} type="video/mp4" />
        </video>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={9}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
            style={frostedGlass}
            sx={{ mt: { xs: 15, lg: -20 }, mb: { xs: 15, lg: 0 } }}
          >
            <Grid item mt={1} mb={1}>
              <MKTypography
                variant="h1"
                color="text"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span ref={typedJSRef} />
              </MKTypography>
            </Grid>

            <MKTypography variant="h4" color="text" mt={1} mb={0} px={{ xs: 3, lg: 6 }}>
              Hi! im Nicklas Dupont, welcome to my resume website.
            </MKTypography>
            <MKTypography variant="body2" color="text" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
              “People in this world shun people for being great, for being a bright color, for
              standing out. But the time is now, to be okay with being the greatest you.” - Kanye
              West
            </MKTypography>
            <MKTypography variant="h6" color="text" textTransform="uppercase" mb={3}>
              connect with me on:
            </MKTypography>
            <Stack direction="row" spacing={6} mx="auto">
              <MKTypography
                component={Link}
                href="#"
                variant="body1"
                onClick={(e) => e.preventDefault()}
              >
                <MKBox component="i" color="text" className="fab fa-facebook" />
              </MKTypography>
              <MKTypography
                component={Link}
                href="#"
                variant="body1"
                onClick={(e) => e.preventDefault()}
              >
                <MKBox component="i" color="text" className="fab fa-instagram" />
              </MKTypography>
              <MKTypography
                component={Link}
                href="#"
                variant="body1"
                onClick={(e) => e.preventDefault()}
              >
                <MKBox component="i" color="text" className="fab fa-twitter" />
              </MKTypography>
              <MKTypography
                component={Link}
                href="#"
                variant="body1"
                onClick={(e) => e.preventDefault()}
              >
                <MKBox component="i" color="text" className="fab fa-google-plus" />
              </MKTypography>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default ResumeHeader;
