// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import {
  AboutUsOption,
  AboutUsOptionInverted,
} from "pages/LandingPages/Coworking/components/AboutUsOption";

function ResumeFeatureWork() {
  const containerLeft = (company, position, period, description, option1, option2, option3) => (
    <>
      <Container>
        <Grid container alignItems="center" sx={{ pb: 4, pt: 4 }}>
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h2" my={1}>
              {company}
            </MKTypography>
            <MKTypography variant="h4" my={1}>
              {position} • {period}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              {description}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" } }}>
            <Grid container alignItems="top" spacing={{ pt: 4 }}>
              <Grid item xs={12} lg={12}>
                <Stack alignItems="flex-end">
                  <AboutUsOptionInverted icon="code" content={<>{option1}</>} />
                  <AboutUsOptionInverted icon="handyman" content={<>{option2}</>} />
                  <AboutUsOptionInverted icon="supervisor_account" content={<>{option3}</>} />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Divider variant="inset" />
    </>
  );

  const containerRight = (company, position, period, description, option1, option2, option3) => (
    <>
      <Container>
        <Grid container alignItems="center" sx={{ pb: 4, pt: 4 }}>
          <Grid item xs={12} lg={6}>
            <Grid container alignItems="top" spacing={{ pt: 4 }}>
              <Grid item xs={12} lg={8}>
                <Stack alignItems="flex-start">
                  <AboutUsOption icon="mediation" content={<>{option1}</>} />
                  <AboutUsOption icon="monitor" content={<>{option2}</>} />
                  <AboutUsOption icon="supervisor_account" content={<>{option3}</>} />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ ml: { xs: 2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <MKTypography variant="h2" my={1}>
              {company}
            </MKTypography>
            <MKTypography variant="h4" my={1}>
              {position} • {period}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              {description}
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      <Divider variant="inset" />
    </>
  );

  return (
    <MKBox component="section" py={{ xs: 3, md: 6 }}>
      <Grid
        container
        item
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        xs={12}
        lg={12}
        sx={{ mx: "auto", textAlign: "center", marginBottom: 4 }}
      >
        <MKBox
          width="3rem"
          height="3rem"
          borderRadius="lg"
          shadow="md"
          variant="gradient"
          bgColor="dark"
          color="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon fontSize="small" sx={{ opacity: 0.8 }}>
            work
          </Icon>
        </MKBox>
        <MKTypography variant="h1" mt={3}>
          Professional experience
        </MKTypography>
      </Grid>
      {containerLeft(
        "NexusOne",
        "Consultant",
        "March 2022 - present",
        "At NexusOne, we take responsibility for our customers' business-critical digital changes and deliver sparring, participation and counterplay",
        "Frontend solutions",
        "Improve existing solutions",
        "Counseling"
      )}
      {containerRight(
        "Wash World",
        "Frontend Developer",
        "July 2021 - February 2022",
        "Wash World offers light, innovative and environmentally friendly car wash at the best prices on the market, whether you visit us often or infrequently.",
        "Build and maintain in-house software solutions",
        "Design and create beautiful UI.",
        "Continuously optimize and implement UX."
      )}
      {containerLeft(
        "Slagterfagets Fællesudvalg",
        "Web master",
        "February 2019 - Present",
        "Slagterfagets Fællesudvalg in collaboration with the parties and the business schools, has adapted and developed our educations with a focus on interdisciplinarity and wholeness.",
        "Build and maintain website with Wordpress",
        "Establish connection to external companies",
        "Extract statistics for all educations under Slagterfagets Fællesudvalg."
      )}
      {containerRight(
        "Freelance Developer",
        "React | React Native",
        "january 2021 - February 2022",
        "",
        "Design and build React Native Apps",
        "Optimize App Performance",
        "Counseling"
      )}
      {containerLeft(
        "Xellia Pharmacuticals",
        "Student assistent",
        "March 2019 - December 2020",
        "Xellia Pharmaceuticals is a specialty pharmaceutical company focused on providing important anti-infective treatments against serious and often life-threatening infections.",
        "Build and test client connections with XML.",
        "Verify data in EU/US Database",
        "Set-up new computers and work-stations"
      )}
    </MKBox>
  );
}

export default ResumeFeatureWork;
